<template>
  <section class="benner">
    <h1>{{ $t('Head.Lecturer') }}</h1>
    <!-- 讲师专区 -->
    <p>{{ $t('teacher.banner_tip') }}</p>
    <!-- 企业内训讲师资源，可以快速了解企业师资力量 -->
  </section>

  <section class="tab-wrap">
    <div class="content">
      <a-tabs size="large" v-model:activeKey="activeKey" @change="tabChange">
        <!-- 讲师信息 -->
        <a-tab-pane key="info" :tab="$t('teacher.lecturer_infor')">
          <info />
        </a-tab-pane>
        <!-- 申请记录 -->
        <a-tab-pane key="apply" :tab="$t('teacher.application_record')" v-if="hasApply">
          <apply />
        </a-tab-pane>
        <!-- 历史聘期 -->
        <a-tab-pane key="tenure" :tab="$t('teacher.historical_tenure')" v-if="hasApply">
          <tenure />
        </a-tab-pane>
        <!-- 荣誉 -->
        <a-tab-pane key="certificate" :tab="$t('teacher.honor')">
          <certificate />
        </a-tab-pane>
      </a-tabs>
    </div>
  </section>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import info from "./components/info.vue";
import apply from "./components/apply.vue";
import tenure from "./components/tenure.vue";
import certificate from "./components/certificate.vue";
export default {
  components: {
    info,
    apply,
    tenure,
    certificate,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const activeKey = ref("info");

    // const host = location.host;
    // const isZf =
    //   host.includes("zftest") ||
    //   host.includes("zhong") ||
    //   host.includes("zhongfu");

    const tabChange = (e) => {
      location.hash = e;
    };

    const hasApply = ref(false);
    watch(
      () => store.getters.companyInfo,
      (company) => {
        if (company.menu && company.menu.indexOf("lecturerapply") !== -1) {
          hasApply.value = true;
        }
      },
      { immediate: true }
    );

    watch(
      () => route.hash,
      (val) => {
        if (val) activeKey.value = val.slice(1);
      },
      { immediate: true }
    );

    return {
      activeKey,
      hasApply,
      tabChange,
    };
  },
};
</script>

<style lang="less" scoped>
.benner {
  height: 324px;
  background-image: url("../../assets/image/teacher_calendar_banner.png");
  background-position: center;
  background-repeat: repeat-y;
  .mixinFlex(center; center; column);
  h1 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 2px;
  }
  p {
    color: #fff;
    font-size: 26px;
    margin-bottom: 60px;
  }
}

.tab-wrap {
  margin-top: -60px;
  padding-bottom: 60px;
  .content {
    .mixinWrap();
    padding: 3px 30px 20px;
    background-color: #fff;
  }
}
</style>